:root {
  --bg-color: #f5f7fa;
  --color-primary: #0296ec;
  --color-error: #ec5802;
  --color-success: #21ec02;
  --color-hny-lime: #64ba00;
  --color-hny-pacific: #0298ec;
  --color-hny-honey: #ffb000;
  --color-hny-tango: #f96e10;
  --color-hny-indigo: #51368d;
  --font-family-sans: Roboto, sans-serif;
}

footer {
  margin-top: 10rem;
}

.footer {
  border-top: 1rem groove var(--color-grey);
  padding: 1rem;
}

header {
  text-align: center;
  margin: 5px;
}

img.small-logo, img.observaquiz-horizontal-logo {
  object-fit: scale-down;
  height: 4rem;
  margin-right: 1rem;
  display: inline;
}

.error {
  color: var(--color-error);
}

.booth-game-tracker {
  background-color: var(--color-hny-indigo);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  margin: 1px 0 0;
  display: flex;
}

.booth-game-tracker > div {
  border-radius: 2px;
  width: 50%;
  height: 1rem;
  margin: 2px;
  transition: all .5s linear;
}

.booth-game-tracker > .completed-step {
  background-color: var(--color-hny-lime);
}

.booth-game-tracker > .you-are-here {
  background-color: var(--color-hny-pacific);
  height: 1.3rem;
}

.booth-game-tracker > .incomplete-step {
  background-color: var(--color-hny-honey);
}

.inner-booth-game-tracker {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 1rem;
  margin: 1px;
  display: flex;
}

.inner-booth-game-tracker > div {
  border-radius: .65rem;
  width: 1.3rem;
  height: 1.3rem;
  transition: all .5s linear;
}

.inner-booth-game-tracker > .completed-step {
  background-color: var(--color-hny-lime);
}

.inner-booth-game-tracker > .you-are-here {
  background-color: var(--color-hny-tango);
  border-radius: .9rem;
  width: 1.8rem;
  height: 1.8rem;
}

.inner-booth-game-tracker > .incomplete-step {
  background-color: var(--color-hny-honey);
}

.completed-step .inner-booth-game-tracker > .completed-step {
  background-color: var(--color-hny-lime);
  width: 0;
  height: 0;
}

#tracing-tracker {
  color: #fff;
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
}

#tracing-tracker p {
  margin-bottom: 0;
}

#tracing-tracker a:link, #tracing-tracker a:visited {
  color: #fff;
  text-decoration: underline;
}

#tracing-tracker-placeholder {
  background-color: var(--color-hny-indigo);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin: 0 0 16px;
  transition: all .5s linear;
}

.fine-print {
  font-size: small;
}

#multiple-choice {
  padding-top: 20px;
}

#moniker {
  max-width: 15rem;
  margin: 10px;
  display: inline-block;
}

section.step {
  border-style: outset;
  border-color: var(--color-lightGrey);
  padding: 10px 10px 10px 15px;
}

.section-header h3 {
  margin: 0;
}

.instructions-flex-parent {
  flex-flow: wrap;
  display: flex;
}

.instructions-flex-parent div.instructions-movie {
  flex-grow: 2;
  flex-basis: 400px;
}

.instructions-movie img {
  border: 2px var(--color-darkGrey) inset;
  padding: 2px;
}

.instructions-flex-parent div.instructions-list {
  flex-grow: 1;
  flex-basis: 400px;
  margin-right: 10px;
}

.screenshot {
  border: 2px var(--color-darkGrey) inset;
  min-width: 300px;
  max-width: 80%;
  padding: 2px;
}

.opinion-icon {
  width: 3rem;
  height: 3rem;
  margin-right: .5rem;
}

.buttonPopOut {
  color: #fff;
  height: 1em;
}

/*# sourceMappingURL=index.34771e97.css.map */
