:root {
  /* variables from chota */
  --bg-color: #f5f7fa;
  /* --bg-secondary-color: #f3f3f6; */
  --color-primary: #0296ec;
  /* --color-lightGrey: #d2d6dd;
  --color-grey: #747681;
  --color-darkGrey: #3f4144; */
  --color-error: #ec5802;
  --color-success: #21ec02;
  --color-hny-lime: #64ba00;
  --color-hny-pacific: #0298ec;
  --color-hny-honey: #ffb000;
  --color-hny-tango: #f96e10;
  --color-hny-indigo: #51368d;
  /* also good with the primary, tetradic: #CD02EC */
  /* --grid-maxWidth: 120rem;
  --grid-gutter: 2rem;
  --font-size: 1.6rem;
  --font-color: #333333; */
  --font-family-sans: Roboto, sans-serif;
  /* --font-family-mono: monaco, "Consolas", "Lucida Console", monospace; */
}

footer {
  margin-top: 10rem;
}

.footer {
  border-top: 1rem groove var(--color-grey);
  padding: 1rem;
}

header {
  margin: 5px;
  text-align: center;
}

img.small-logo {
  display: inline;
  object-fit: scale-down;
  height: 4rem;
  margin-right: 1rem;
}

img.observaquiz-horizontal-logo {
  display: inline;
  object-fit: scale-down;
  height: 4rem;
  margin-right: 1rem;
}

.error {
  color: var(--color-error);
}

/* Tracker team */

.booth-game-tracker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-hny-indigo);
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  align-items: center;
  margin: 1px 0px 0px 0px;
  height: 2rem;
}

.booth-game-tracker>div {
  margin: 2px;
  height: 1rem;
  border-radius: 2px;
  width: 50%;
  transition: all 0.5s linear;
}

.booth-game-tracker>.completed-step {
  background-color: var(--color-hny-lime)
}

.booth-game-tracker>.you-are-here {
  background-color: var(--color-hny-pacific);
  height: 1.3rem;
}

.booth-game-tracker>.incomplete-step {
  background-color: var(--color-hny-honey);
}

.inner-booth-game-tracker {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  align-items: center;
  margin: 1px 1px 1px 1px;
  height: 1rem;
}

.inner-booth-game-tracker>div {
  height: 1.3rem;
  border-radius: 0.65rem;
  width: 1.3rem;
  transition: all 0.5s linear;
}

.inner-booth-game-tracker>.completed-step {
  background-color: var(--color-hny-lime);
}

.inner-booth-game-tracker>.you-are-here {
  background-color: var(--color-hny-tango);
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 0.9rem;
}

.inner-booth-game-tracker>.incomplete-step {
  background-color: var(--color-hny-honey);
}

.completed-step .inner-booth-game-tracker>.completed-step {
  background-color: var(--color-hny-lime);
  height: 0rem;
  width: 0rem;
}


#tracing-tracker {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
}

#tracing-tracker p {
  margin-bottom: 0px;
}

#tracing-tracker a:link {
  color: white;
  text-decoration: underline;
}

#tracing-tracker a:visited {
  color: white;
  text-decoration: underline;
}

/* TODO: get this transition to work */
#tracing-tracker-placeholder {
  transition: all 0.5s linear;
  background-color: var(--color-hny-indigo);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin: 0px 0px 16px 0px;
}

.fine-print {
  font-size: small;
}

#multiple-choice {
  padding-top: 20px;
}

#moniker {
  max-width: 15rem;
  display: inline-block;
  margin: 10px;
}

section.step {
  border-style: outset;
  padding: 10px;
  padding-left: 15px;
  border-color: var(--color-lightGrey);
}

.section-header h3 {
  margin: 0px;
}

.instructions-flex-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap
}

.instructions-flex-parent div.instructions-movie {
  flex-basis: 400px;
  flex-grow: 2;
}

.instructions-movie img {
  border: 2px var(--color-darkGrey) inset;
  padding: 2px;
}

.instructions-flex-parent div.instructions-list {
  flex-basis: 400px;
  flex-grow: 1;
  margin-right: 10px;
}

.screenshot {
  max-width: 80%;
  min-width: 300px;
  border: 2px var(--color-darkGrey) inset;
  padding: 2px;
}

.opinion-icon {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.buttonPopOut {
  color: white;
  height: 1em;
}